import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './login';
import Form from './formulario';
import Qr from './qr';
import Manuales2 from './Manuales2';
import Clientes from './cliente';
import PrivateRoute from './PrivateRoute';
import Celular from './celular';
import Celular_formulario from './celularformulario';
import Login_movil from './logmovil';
import Clientes_movil from './clientecelular';
const AppRoutes = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
       if (window.location.protocol !== "https:") {
           window.location.href = "https://" + window.location.hostname + window.location.pathname + window.location.search;
        }
        const height = window.innerHeight;
        const width = window.innerWidth;
        const maxPhoneWidth = 1024;
        const maxPhoneHeight = 812; 
        const maxTabletWidth = 1024; 
        const maxTabletHeight = 1366; 
        if (width <= maxPhoneWidth )  {
            const savedZoom = localStorage.getItem('zoomLevel') || "100%";
            document.body.style.zoom = savedZoom;

            if (savedZoom === "100%") {
                document.body.style.zoom = "50%";
                localStorage.setItem('zoomLevel', "50%");
                setTimeout(() => {
                    window.location.reload();
                }, 100);
            }
            setIsMobile(true);
        } else if (width <= maxTabletWidth && height <= maxTabletHeight) { 
            setIsMobile(true);

            const savedZoom = localStorage.getItem('zoomLevel') || "100%";
            document.body.style.zoom = savedZoom;

            if (savedZoom === "100%") {
                document.body.style.zoom = "50%";
                localStorage.setItem('zoomLevel', "50%");
                setTimeout(() => {
                    window.location.reload();
                }, 100);
            }

        }
        else {
            const savedZoom = localStorage.getItem('zoomLevel') || "100%";
            document.body.style.zoom = savedZoom;

            if (savedZoom === "100%") {
                document.body.style.zoom = "50%";
                localStorage.setItem('zoomLevel', "50%");
                setTimeout(() => {
                    window.location.reload();
                }, 100);
            }
            setIsMobile(false);
       }
    }, []);

    return (
        <Router>
            <Routes>
                <Route path="/" element={isMobile ? <Login_movil /> : <Login />} />
                <Route path="/formulario" element={<PrivateRoute element={Form} />} />
                <Route path="/cliente" element={<PrivateRoute element={Clientes} />} />
                <Route path="/qr" element={isMobile ? <Qr /> : <Qr />} />
                <Route path="/manuales" element={isMobile ? <Manuales2 /> : <Manuales2 />} />
                <Route path="/formulariomovil" element={<PrivateRoute element={Celular_formulario} />} />
                <Route path="/logmovil"element={<PrivateRoute element={Login_movil} />} ></Route>
                <Route path="/clientemovil"element={<PrivateRoute element={Clientes_movil} />} ></Route>


            </Routes>
        </Router>
    );
}

export default AppRoutes;
