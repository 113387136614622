import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './nuevo2.css';
import config from './config';

const Login = () => {
    const [userType, setUserType] = useState('');
    const [password, setPassword] = useState('');
    const [currentImage, setCurrentImage] = useState(0);
    const [showInitialImage, setShowInitialImage] = useState(true);
    const navigate = useNavigate();

    const images = [
        `${process.env.PUBLIC_URL}/bus4.png`,
        `${process.env.PUBLIC_URL}/bus5.png`,
        `${process.env.PUBLIC_URL}/bus6.png`,
    ];

    useEffect(() => {
        const initialImageTimeout = setTimeout(() => {
            setShowInitialImage(false);

            const interval = setInterval(() => {
                setCurrentImage((prevImage) => (prevImage + 1) % images.length);
            }, 2500);

            return () => clearInterval(interval);
        }, 2500);

        return () => clearTimeout(initialImageTimeout);
    }, [images.length]);
    
    
    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${config.apiBaseUrl}/api/log`, {
                password: password,
                userType: userType
            });
            console.log(response.data, "los datos son:");
    
            const token = response.data.token;
            localStorage.setItem('token', token);
    
            const userTypeResponse = response.data.userType;
    
            if (userTypeResponse === 'proveedor') {
                navigate('/formulario');
                alert("Acceso correcto, bienvenido");
            } else if (userTypeResponse === 'cliente') {
                navigate('/cliente');
            }
        } catch (error) {
            alert('Credenciales incorrectas');
        }
    };
    
    
    const backgroundImageStyles = {
        position: 'absolute', 
        top: 10,               
        left: 10,             
        width: '200vw',      
        height: '200vh',      
        background: `url(${process.env.PUBLIC_URL}/bus4.png) center center no-repeat`, 
        backgroundSize: 'cover', 
        transition: 'background-image 2s ease-in-out', 
        zIndex: -1 
    };

    const login24 = {
        width: "700px",
        height: "860px",
        boxShadow: "0px 8px 80px",
        top: "250px",
        left: "450px"
    };

    return (
        //HOLA AMIGO PODEMOS VER TU IP
        <div style={backgroundImageStyles}>
            <div>
                
                <div className="card p-4" style={login24} data-aos="flip-left">
                <br></br>
                <br></br>
            


                    <div className="text-center">
                        <img src={`${process.env.PUBLIC_URL}/Marcologo1.png`} alt="Logo" className="img-circle mb-3" />
                        <br></br>
                        <br></br>
                        <br></br>

                        <p style={{ fontSize: "40px", textAlign: "center", color: "black", boxShadow: "0 ,8 ,10" }}>
                            Gestor de formularios
                        </p>
                        
                    </div>
                    <form onSubmit={handleLogin} className='formulario'>
                        <div className="mb-3">
                            <label htmlFor="userType" className="form-label label1">Tipo de formulario</label>
                            <select
                                className="form-control"
                                id="userType"
                                value={userType}
                                onChange={(e) => setUserType(e.target.value)}
                                style={{width:"500px",height:"70px",borderRadius:"35px",textAlign:"center"     }}

                            >
                                <option value="" disabled>Selecciona el tipo de formulario</option>
                                <option value="proveedor">Proveedor</option>
                                <option value="cliente">Cliente</option>
                                
                            </select>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="password" className="form-label label1">Contraseña</label>
                            <br />
                            <input
                                type="password"
                                className="form-control22"
                                id="password"
                                placeholder="Ingresa tu contraseña"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                style={{width:"500px",height:"70px",borderRadius:"35px",textAlign:"center"
                                }}
                            />
                        </div>
                        <div className="d-grid">
                            <br />
                            <br />

                            <button style={{width:"350px",height:"70px",borderRadius:"35px"
                            }} type="submit" className="btn btn-inicio">Ingresar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
