import React , { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';



const backgroundImageStyles = 
{
    position: 'absolute', 
    top: 10,               
    left: 10,             
    width: '100vw',      
    height: '100vh',      
    background: `url(${process.env.PUBLIC_URL}/bus10.png) center center no-repeat`, 
    backgroundSize: 'cover', 
    transition: 'background-image 2s ease-in-out', 
    zIndex: -1 
};

const Celular = () => {

    useEffect(() => {
        AOS.init({
            duration: 1000, 
        });
    }, []);
    return (
        <div style={backgroundImageStyles}>
        <div className="container d-flex justify-content-center align-items-center vh-100">
            <div className="text-center"  data-aos="fade-up">
                <h1 style={{ fontSize: "30px", textAlign: "center", color: "white", boxShadow: "0 ,10 ,40" }} >Aplicativo no disponible en dispositivos móviles</h1>
                <img src={`${process.env.PUBLIC_URL}/30.png`} alt="Logo" className="img-circle mb-3" style={{ width:"200px" }} />
                </div>
        </div>
        </div>
    );
};

export default Celular;

