import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './nuevo2.css';
import config from './config';

const Login = () => {
    const [userType, setUserType] = useState('');
    const [password, setPassword] = useState('');
    const [currentImage, setCurrentImage] = useState(0);
    const [showInitialImage, setShowInitialImage] = useState(true);
    const navigate = useNavigate();

    const images = [
        `${process.env.PUBLIC_URL}/bus4.png`,
        `${process.env.PUBLIC_URL}/bus5.png`,
        `${process.env.PUBLIC_URL}/bus6.png`,
    ];

    useEffect(() => {
        const initialImageTimeout = setTimeout(() => {
            setShowInitialImage(false);

            const interval = setInterval(() => {
                setCurrentImage((prevImage) => (prevImage + 1) % images.length);
            }, 2500);

            return () => clearInterval(interval);
        }, 2500);

        return () => clearTimeout(initialImageTimeout);
    }, [images.length]);
    
    
    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${config.apiBaseUrl}/api/logmovil`, {
                password: password,
                userType: userType
            });
            console.log(response.data, "los datos son:");
    
            const token = response.data.token;
            localStorage.setItem('token', token);
    
            const userTypeResponse = response.data.userType;
    
            if (userTypeResponse =='proveedorcelular') {
                navigate('/formulariomovil');
                alert("Acceso correcto, bienvenido");
            } else if (userTypeResponse == 'clientecelular') {
                navigate('/clientemovil');
            }
        } catch (error) {
            alert('Credenciales incorrectas');
        }
    };
    
    
    const backgroundImageStyles = {
        position: 'absolute', 
        top: 0,               
        left: 0,             
        width: '200vw',      
        height: '200vh',      
        background: `url(${process.env.PUBLIC_URL}/portal1.png) center center no-repeat`, 
        backgroundSize: 'cover', 
        transition: 'background-image 2s ease-in-out', 
        zIndex: -1 
    };

    const loginContainerStyles = {
        width: "100%", 
        maxWidth: "1200px", 
        boxShadow: "0px 8px 80px", 
        margin: "350px auto", 
        padding: "100px", 
        borderRadius: "20px",
        backgroundColor: 'white',
        height: "500%"



    };

    return (
        <div style={backgroundImageStyles}>
            <div>
                <div className="card p-4" style={loginContainerStyles} data-aos="flip-left">
                    <div className="text-center">
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>

                        <img src={`${process.env.PUBLIC_URL}/Marcologo1.png`} alt="Logo" className="img-circle mb-3" style={{ height: "80px" }} />
                        <br />
                        <p style={{ fontSize: "30px", textAlign: "center", color: "black", boxShadow: "0 ,8 ,10" }}>
                            Gestor de formularios
                        </p>
                    </div>
                    <form onSubmit={handleLogin} className='formulario'>
                        <div className="mb-3">
                            <label htmlFor="userType" className="form-label label1">Tipo de formulario</label>
                            <select
                                className="form-control"
                                id="userType"
                                value={userType}
                                onChange={(e) => setUserType(e.target.value)}
                                style={{ width: "100%", height: "50px", borderRadius: "10px", textAlign: "center" }}
                            >
                                <option value="" disabled>Selecciona el tipo de formulario</option>
                                <option value="proveedorcelular">Proveedor</option>
                                <option value="clientecelular">Cliente</option>
                            </select>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="password" className="form-label label1">Contraseña</label>
                            <br />
                            <input
                                type="password"
                                className="form-control"
                                id="password"
                                placeholder="Ingresa tu contraseña"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                style={{ width: "100%", height: "50px", borderRadius: "10px", textAlign: "center" }}
                            />
                        </div>
                        <div className="d-grid">
                            <br />
                            <button style={{ width: "100%", height: "50px", borderRadius: "10px" }} type="submit" className="btn btn-primary">Ingresar</button>
                        </div>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
