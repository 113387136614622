import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { FaFilePdf } from 'react-icons/fa';


const containerStyle = {
    backgroundImage: `url(${process.env.PUBLIC_URL}/bus10.png)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '200vh',
    padding: '5% 0'
};

const App = () => {

    //derivar http a https
    useEffect(() => {
        if (window.location.protocol !== "https:") 
            {
            window.location.href = "https://" + window.location.hostname + window.location.pathname + window.location.search;
        }

      }, []);
    const [isDownloading, setIsDownloading] = useState(false);

    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);

    const downloadFile = async (apiEndpoint) => {
        setIsDownloading(true);
        try {
            const response = await axios.get(apiEndpoint, {
                responseType: 'blob'
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', apiEndpoint.split('/').pop() + '.pdf');
            document.body.appendChild(link);
            link.click();
            setIsDownloading(false);
        } catch (error) {
            console.error('Error al descargar el archivo:', error);
            setIsDownloading(false);
        }
    };

    return (
        <div style={containerStyle}>
            <div          data-aos="flip-left" className="container mt-5">
            <div className="container-background d-flex flex-column align-items-center">
                <div className="text-center" data-aos="flip-left">
                    <img src="/Marcologo1.png" alt="Logo" className="img-circle mb-3" />
                </div>
                <br></br>
                <br></br>
                <br></br>

                <h1 data-aos="flip-left" style={{ color: 'white', fontFamily: 'Josefin Sans, sans-serif', textAlign: 'center', textShadow: 'rgba(0, 0, 0, 0.3) 0px 4px 8px' }}>
                    DESCARGAS
                </h1>
                <p data-aos="flip-left" style={{ color: 'white', fontFamily: 'Josefin Sans, sans-serif', textAlign: 'center', textShadow: 'rgba(0, 0, 0, 0.3) 0px 4px 8px' }}>
                    Haga click en el elemento a descargar
                </p>
                {isDownloading && (
                    <p style={{ color: 'yellow', fontFamily: 'Josefin Sans, sans-serif', textAlign: 'center' }}>
                        DESCARGANDO ARCHIVO 🚍...
                    </p>
                )}
                <br />
                <div className="icon-buttons d-flex flex-column align-items-center">
                    <div className="mb-2">
                        <button 
                            className="btn btn-primary d-block" 
                            onClick={() => downloadFile('https://api.marcopoloargentina.com.ar/api/qrmanual')}
                        >
                            <FaFilePdf /> Manual de operación
                        </button>
                    </div>
                    <br />
                    <div>
                        <button 
                            className="btn btn-primary d-block" 
                            onClick={() => downloadFile('https://api.marcopoloargentina.com.ar/api/qrgarantia')}
                        >
                            <FaFilePdf /> Manual de garantia
                        </button>
                    </div>
                </div>
                
            </div>
            
        </div>
        </div>
        
    );
};

export default App;
