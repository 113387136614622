import React, { useState,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import config from './config';

const titulo = {
    color: 'white',
    fontFamily: '"Josefin Sans", sans-serif',
    fontOpticalSizing: 'auto',
    fontStyle: 'Regular 400',
    textAlign: 'center',
    textShadow: '0 4px 8px rgba(0, 0, 0, 0.3)'
};

const titulo2 = {
    color: 'white',
    fontFamily: '"Josefin Sans", sans-serif',
    fontOpticalSizing: 'auto',
    fontStyle: 'Regular 400',
    textAlign: 'center',
    textShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
    fontSize: '25px'
};

const Qr = () => {

//derivar http a https
    useEffect(() => {
        if (window.location.protocol !== "https:") 
            {
            window.location.href = "https://" + window.location.hostname + window.location.pathname + window.location.search;
        }

      }, []);
    const [formData, setFormData] = useState({
        email: '',
        empresa: '',
        nombre: '',
        apellido: '',
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const validateForm = () => {
        const newErrors = {};
        Object.keys(formData).forEach(key => {
            if (!formData[key]) {
                newErrors[key] = 'Este campo es requerido';
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            alert('Por favor complete todos los campos.');
            return;
        }

        try {
            const response = await fetch(`${config.apiBaseUrl}/api/qrcomercial`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                window.location.href = '/manuales';
            } else {
                alert('Error al enviar el formulario');
            }
        } catch (error) {
            alert('Error al enviar el formulario');
        }
    };

    const containerStyle = {
        backgroundImage: `url(${process.env.PUBLIC_URL}/bus10.png)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '200vh',
        padding: '5% 0'
    };

    return (
        <div style={containerStyle}>
            <div className="container mt-5">
                <img
                    src={`${process.env.PUBLIC_URL}/Marcologo1.png`}
                    alt="Logo"
                    style={{ display: 'block', margin: 'auto' }}
                    data-aos="flip-left"
                />
                <br /><br />

                <h2                       data-aos="flip-left"
 className="text-center mb-4" style={titulo}>Descarga de manuales</h2>
                <br />
                <p                      data-aos="flip-left"
 style={titulo}>Para descargar los manuales por favor complete los campos</p>
                <form                      data-aos="flip-left"
 onSubmit={handleSubmit}>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6 col-lg-4 mb-3">
                            <label style={titulo2} htmlFor="empresa" className="form-label label2">Nombre de la Empresa</label>
                            <input type="text" className="form-control" id="empresa" value={formData.empresa} onChange={handleChange} placeholder="Empresa" />
                            {errors.empresa && <small className="text-danger">{errors.empresa}</small>}
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-3">
                            <label style={titulo2} htmlFor="nombre" className="form-label label2">Nombre del usuario</label>
                            <input type="text" className="form-control" id="nombre" value={formData.nombre} onChange={handleChange} placeholder="Nombre" />
                            {errors.nombre && <small className="text-danger">{errors.nombre}</small>}
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6 col-lg-4 mb-3">
                            <label style={titulo2} htmlFor="apellido" className="form-label label2">Apellido del usuario</label>
                            <input type="text" className="form-control" id="apellido" value={formData.apellido} onChange={handleChange} placeholder="Apellido" />
                            {errors.apellido && <small className="text-danger">{errors.apellido}</small>}
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-3">
                            <label style={titulo2} htmlFor="email" className="form-label label2">Email</label>
                            <input type="email" className="form-control" id="email" value={formData.email} onChange={handleChange} placeholder="Email" />
                            {errors.email && <small className="text-danger">{errors.email}</small>}
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6 col-lg-4">
                            <button type="submit" className="btn btn-primary w-100">Enviar</button>
                        </div>
                    </div>
                </form>
                <br /><br /><br /><br /><br /><br />
            </div>
        </div>
    );
};

export default Qr;
