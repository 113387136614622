import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './login1.css';
import config from './config';

const titulo = {
    fontFamily: '"Josefin Sans", sans-serif',
    fontOpticalSizing: 'auto',
    fontStyle: 'Regular 400',
    textAlign: 'center',
    textShadow: '0 4px 5px rgba(0, 0, 0, 0.3)',
    fontSize: '40px' 
};

const overlayStyles = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '200vw',
    height: '300vh', 
    background: `url(${process.env.PUBLIC_URL}/portal1.png) no-repeat center center`,
    backgroundSize: 'cover',
    opacity: 0.7,
    zIndex: 2
};

const formContainerStyles = {
    background: 'white',
    padding: '20px', 
    borderRadius: '20px', 
    boxShadow: '0 10px 30px rgba(10, 0, 5, 8)',
    marginBottom: '0px', 
    width: '95%',
    maxWidth: '600px', 
    position: 'relative',
    zIndex: 3,
    top: '10vh', 
    margin: '0 auto' 
};

const provincias = [
    "Buenos Aires", "Catamarca", "Chaco", "Chubut", "Córdoba", "Corrientes", "Entre Ríos", "Formosa", 
    "Jujuy", "La Pampa", "La Rioja", "Mendoza", "Misiones", "Neuquén", "Río Negro", "Salta", 
    "San Juan", "San Luis", "Santa Cruz", "Santa Fe", "Santiago del Estero", "Tierra del Fuego", 
    "Tucumán"
];

function Clientes_celular() {
    const [submitSuccess, setSubmitSuccess] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [formData, setFormData] = useState({
        razonSocial: '',
        direccion: '',
        localidad: '',
        provincia: '',
        codigoPostal: '',
        telefono: '',
        cuit: '',
        iibb: '',
        cbu: '',
        email: '',
        exclusionCertificados: null,
        afip: null,
        contribuyenteLocal: null,
        situacionIVA: '',
        condicionIngresosBrutos: ''
    });

    const [files, setFiles] = useState({
        exclusionCertificados: [],
        afip: [],
        contribuyenteLocal: [],
        formulario1276: []
    });

    const [errors, setErrors] = useState({});
    const [showFileUpload, setShowFileUpload] = useState(false);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleFileChange = (e, field) => {
        const { files } = e.target;
        const newFiles = Array.from(files);
        if (newFiles.some(file => file.type !== 'application/pdf')) {
            alert('Solo se permiten archivos PDF');
            return;
        }
        setFiles(prevFiles => ({ ...prevFiles, [field]: newFiles }));
    };

    const validateForm = () => {
        const newErrors = {};

        Object.keys(formData).forEach(key => {
            if (formData[key] === "" && key !== 'iibb' && key !== 'exclusionCertificados') {
                newErrors[key] = 'Este campo es requerido';
            }
        });

        if (formData.condicionIngresosBrutos !== 'Exento' && formData.iibb === "") {
            newErrors.iibb = 'Este campo es requerido';
        }

        if (formData.cbu.length !== 22) {
            newErrors.cbu = 'El CBU debe tener exactamente 22 números';
        } else if (!/^\d{22}$/.test(formData.cbu)) {
            newErrors.cbu = 'El CBU debe contener solo números';
        }

        if (formData.cuit.length !== 11) {
            newErrors.cuit = 'El CUIT debe tener exactamente 11 números';
        } else if (!/^\d{11}$/.test(formData.cuit)) {
            newErrors.cuit = 'El CUIT debe contener solo números';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleNext = () => {
        if (validateForm()) {
            setShowFileUpload(true);
        } else {
            alert('Por favor, complete todos los campos requeridos.');
        }
    };

    const handleBack = () => {
        setShowFileUpload(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const data = new FormData();
        Object.keys(formData).forEach(key => {
            data.append(key, formData[key]);
        });

        Object.keys(files).forEach(fileCategory => {
            files[fileCategory].forEach((file, index) => {
                const fileName = `${formData.razonSocial}_${formData.cuit}_${fileCategory}_${index + 1}.pdf`;
                data.append('archivos', file, fileName);
            });
        });
        try {
            const response = await fetch(`${config.apiBaseUrl}/api/solicitudesclientescelular`, {
                method: 'POST',
                body: data
            });
            if (response.ok) {
                setSubmitSuccess(true);
                setTimeout(() => {
                    window.location.href = '/';
                }, 4500); 
            } else {
                alert('Error al enviar el formulario');
            }
        } catch (error) {
            alert('Error al enviar el formulario');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className='App'>
            <div className="overlay" style={overlayStyles} data-aos="flip-left"></div>
            <br /><br />
            <div className="container mt-5" style={{ maxWidth: '2000px', marginBottom: '0px' }}>
                <div className="row justify-content-center">
                    <div className="col-md-12" style={formContainerStyles} data-aos="flip-left">
                    <br /><br /><br />
                    <br />

                        <div className="text-center">
                            <img src={`${process.env.PUBLIC_URL}/Marcologo1.png`} alt="Logo" style={{ height: "80px" }} />
                            <br /><br /><br />
                        </div>
                        {isSubmitting ? (
                            <div className="text-center">
                                <img style={{ width: "400px", height: "400px" }} src={`${process.env.PUBLIC_URL}/log.gif`} alt="Enviando..." />
                            </div>
                        ) : submitSuccess ? (
                            <div className="text-center">
                                <img src={`${process.env.PUBLIC_URL}/log1.png`} style={{ width: "200px", height: "200px" }} alt="Enviado con éxito" />
                                <h2 style={{ fontSize: "25px", color: "grey", textShadow: "0px 0px 6px", fontFamily: '"Josefin Sans", sans-serif' }}>SU SOLICITUD FUE ENVIADA CORRECTAMENTE</h2>
                                <p style={{ fontSize: "25px", color: "#ff6600", textShadow: "0px 0px 6px", fontFamily: '"Josefin Sans", sans-serif' }}>BIENVENIDO A MARCOPOLO ARGENTINA</p>
                            </div>
                        ) : (
                            <form onSubmit={handleSubmit} className="text-center">
                                {!showFileUpload && (
                                    <>
                                        <h2 className="text-center mb-4" style={titulo}>Datos del Cliente</h2>
                                        <p style={{ fontSize: "20px", color: "#ff6600", textShadow: "0px 0px 6px", fontFamily: '"Josefin Sans", sans-serif' }}>Todos los campos son requeridos</p>
                                        <div className="row justify-content-center">
                                            <div className="col-sm-12 col-md-10 col-lg-8 mb-3">
                                                <label htmlFor="razonSocial" className="form-label">Razón Social</label>
                                                <input type="text" className="form-control" id="razonSocial" value={formData.razonSocial} onChange={handleChange} placeholder="Ingresa la razón social" required />
                                                {errors.razonSocial && <small className="text-danger">{errors.razonSocial}</small>}
                                            </div>
                                            <div className="col-sm-12 col-md-10 col-lg-8 mb-3">
                                                <label htmlFor="direccion" className="form-label">Dirección</label>
                                                <input type="text" className="form-control" id="direccion" value={formData.direccion} onChange={handleChange} placeholder="Ingresa la dirección" required />
                                                {errors.direccion && <small className="text-danger">{errors.direccion}</small>}
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-sm-12 col-md-10 col-lg-8 mb-3">
                                                <label htmlFor="localidad" className="form-label">Localidad</label>
                                                <input type="text" className="form-control" id="localidad" value={formData.localidad} onChange={handleChange} placeholder="Ingresa la localidad" required />
                                                {errors.localidad && <small className="text-danger">{errors.localidad}</small>}
                                            </div>
                                            <div className="col-sm-12 col-md-10 col-lg-8 mb-3">
                                                <label htmlFor="provincia" className="form-label">Provincia</label>
                                                <select
                                                    className="form-control"
                                                    id="provincia"
                                                    value={formData.provincia}
                                                    onChange={(e) => setFormData({ ...formData, provincia: e.target.value })}
                                                    required >
                                                    <option value="" disabled>Selecciona tu provincia</option>
                                                    {provincias.map(provincia => (
                                                        <option key={provincia} value={provincia}>{provincia}</option>
                                                    ))}
                                                </select>
                                                {errors.provincia && <small className="text-danger">{errors.provincia}</small>}
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-sm-12 col-md-10 col-lg-8 mb-3">
                                                <label htmlFor="codigoPostal" className="form-label">Código Postal</label>
                                                <input type="text" className="form-control" id="codigoPostal" value={formData.codigoPostal} onChange={handleChange} placeholder="Ingresa el código postal" required />
                                                {errors.codigoPostal && <small className="text-danger">{errors.codigoPostal}</small>}
                                            </div>
                                            <div className="col-sm-12 col-md-10 col-lg-8 mb-3">
                                                <label htmlFor="telefono" className="form-label">Teléfono</label>
                                                <input type="text" className="form-control" id="telefono" value={formData.telefono} onChange={handleChange} placeholder="Ingresa el teléfono" required />
                                                {errors.telefono && <small className="text-danger">{errors.telefono}</small>}
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-sm-12 col-md-10 col-lg-8 mb-3">
                                                <label htmlFor="cuit" className="form-label">CUIT</label>
                                                <input type="text" className="form-control" id="cuit" value={formData.cuit} onChange={handleChange} placeholder="Ingresa el CUIT" required />
                                                {errors.cuit && <small className="text-danger">{errors.cuit}</small>}
                                            </div>
                                            <div className="col-sm-12 col-md-10 col-lg-8 mb-3">
                                                <label htmlFor="iibb" className="form-label">Nº de Inscripción IIBB</label>
                                                <input type="text" className="form-control" id="iibb" value={formData.iibb} onChange={handleChange} placeholder="Ingresa el Nº de Inscripción IIBB" required />
                                                {errors.iibb && <small className="text-danger">{errors.iibb}</small>}
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-sm-12 col-md-10 col-lg-8 mb-3">
                                                <label htmlFor="cbu" className="form-label">CBU</label>
                                                <input type="text" className="form-control" id="cbu" value={formData.cbu} onChange={handleChange} placeholder="Ingresa el CBU" required />
                                                {errors.cbu && <small className="text-danger">{errors.cbu}</small>}
                                            </div>
                                            <div className="col-sm-12 col-md-10 col-lg-8 mb-3">
                                                <label htmlFor="email" className="form-label">Email</label>
                                                <input type="email" className="form-control" id="email" value={formData.email} onChange={handleChange} placeholder="Ingresa el email" required />
                                                {errors.email && <small className="text-danger">{errors.email}</small>}
                                            </div>
                                        </div>
                                        <div className="row justify-content-center mb-3">
                                            <div className="col-sm-12 col-md-10 col-lg-8">
                                                <label htmlFor="situacionIVA" className="form-label">Situación IVA</label>
                                                <select
                                                    className="form-control"
                                                    id="situacionIVA"
                                                    value={formData.situacionIVA}
                                                    onChange={(e) => setFormData({ ...formData, situacionIVA: e.target.value })}
                                                    required
                                                >
                                                    <option value="" disabled>Selecciona tu situación IVA</option>
                                                    <option value="Responsable Inscripto">Responsable Inscripto</option>
                                                    <option value="Monotributista">Monotributista</option>
                                                    <option value="Exento">Exento</option>
                                                </select>
                                                {errors.situacionIVA && <small className="text-danger">{errors.situacionIVA}</small>}
                                            </div>
                                        </div>
                                        <div className="row justify-content-center mb-3">
                                            <div className="col-sm-12 col-md-10 col-lg-8">
                                                <label htmlFor="condicionIngresosBrutos" className="form-label">Condición ante Ingresos Brutos</label>
                                                <select
                                                    className="form-control"
                                                    id="condicionIngresosBrutos"
                                                    value={formData.condicionIngresosBrutos}
                                                    onChange={(e) => setFormData({ ...formData, condicionIngresosBrutos: e.target.value })}
                                                    required>
                                                    <option value="" disabled>Selecciona tu condición</option>
                                                    <option value="Contribuyente Local">Contribuyente Local</option>
                                                    <option value="Convenio Multilateral">Convenio Multilateral</option>
                                                    <option value="Regimen Simplificado">Regimen Simplificado</option>
                                                    <option value="Exento">Exento</option>
                                                </select>
                                                {errors.condicionIngresosBrutos && <small className="text-danger">{errors.condicionIngresosBrutos}</small>}
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-md-6">
                                                <br /><br />
                                                <button type="button" className="btn btn-primary btn-block" onClick={handleNext}>Siguiente</button>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {showFileUpload && (
                                    <>
                                        <h3 className="mt-5" style={titulo}>Documentos a Cargar</h3>
                                        <p style={{ color: 'black' }}>LOS DOCUMENTOS DEBEN SER EN FORMATO PDF</p>
                                        <br />
                                        <div className="row justify-content-center mb-3">
                                            <div className="col-sm-12 col-md-10 col-lg-8">
                                                <label htmlFor="afip" className="form-label">Constancia AFIP CUIT (OBLIGATORIO)</label>
                                                <input type="file" id="afip" name="afip" className="form-control" accept="application/pdf" onChange={(e) => handleFileChange(e, "afip")} />
                                                {errors.afip && <small className="text-danger">{errors.afip}</small>}
                                            </div>
                                        </div>
                                        <div className="row justify-content-center mb-3">
                                            <div className="col-sm-12 col-md-10 col-lg-8">
                                                <label htmlFor="iibb" className="form-label">Constancia de Inscripción IIBB o Formulario CM 05 (OBLIGATORIO)</label>
                                                <input type="file" id="iibb" name="iibb" className="form-control" accept="application/pdf" onChange={(e) => handleFileChange(e, "iibb")} />
                                                {errors.iibb && <small className="text-danger">{errors.iibb}</small>}
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-md-6">
                                                <br /><br />
                                                <button type="button" className="btn btn-secondary btn-block" onClick={handleBack}>Atrás</button>
                                                <br /><br />
                                                <button type="submit" className="btn btn-primary btn-block">Enviar</button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Clientes_celular;
