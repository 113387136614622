import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './login1.css';
import { isDesktop } from 'react-device-detect';
import config from './config';


const titulo = {
    fontFamily: '"Josefin Sans", sans-serif',
    fontOpticalSizing: 'auto',
    fontStyle: 'Regular 400',
    textAlign: 'center',
    textShadow: '0 4px 5px rgba(0, 0, 0, 0.3)',
    fontSize: '50px'
};

const overlayStyles = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '200vw',
    height: '2500px',
    background: `url(${process.env.PUBLIC_URL}/bus14.gif) no-repeat center center`,
    backgroundSize: 'cover',
    opacity: 0.7,
    zIndex: 2
};

const formContainerStyles = {
    background: 'white',
    padding: '90px',
    borderRadius: '80px',
    boxShadow: '0 10px 30px rgba(10, 0, 5, 8)',
    paddingTop: '70px',
    marginBottom: '0px', 
    width: '62%',
    height: '100%',
    position: 'relative',
    margin: '0 auto',
    zIndex: 3,
    top: 320,
    left: -70,
};

const provincias = [
    "Buenos Aires", "Catamarca", "Chaco", "Chubut", "Córdoba", "Corrientes", "Entre Ríos", "Formosa", 
    "Jujuy", "La Pampa", "La Rioja", "Mendoza", "Misiones", "Neuquén", "Río Negro", "Salta", 
    "San Juan", "San Luis", "Santa Cruz", "Santa Fe", "Santiago del Estero", "Tierra del Fuego", 
    "Tucumán"
];

function Clientes() {
    const [submitSuccess, setSubmitSuccess] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [formData, setFormData] = useState({
        razonSocial: '',
        direccion: '',
        localidad: '',
        provincia: '',
        codigoPostal: '',
        telefono: '',
        cuit: '',
        iibb: '',
        cbu: '',
        email: '',
        exclusionCertificados: null,
        afip: null,
        contribuyenteLocal: null,
        situacionIVA: '',
        condicionIngresosBrutos: ''
    });

    const [files, setFiles] = useState({
        exclusionCertificados: [],
        afip: [],
        contribuyenteLocal: [],
        formulario1276: []
    });

    const [errors, setErrors] = useState({});
    const [showFileUpload, setShowFileUpload] = useState(false);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleFileChange = (e, field) => {
        const { files } = e.target;
        const newFiles = Array.from(files);
        if (newFiles.some(file => file.type !== 'application/pdf')) {
            alert('Solo se permiten archivos PDF');
            return;
        }
        setFiles(prevFiles => ({ ...prevFiles, [field]: newFiles }));
    };

 const validateForm = () => {
    const newErrors = {};
    let errorMessages = [];

    Object.keys(formData).forEach(key => {
        if (formData[key] === "" && key !== 'iibb' && key !== 'exclusionCertificados') {
            newErrors[key] = 'Este campo es requerido';
            errorMessages.push(`El campo ${key} es requerido.`);
        }
    });

    if (formData.condicionIngresosBrutos !== 'Exento' && formData.iibb === "") {
        newErrors.iibb = 'Este campo es requerido';
        errorMessages.push('Debe proporcionar el Nº de Inscripción IIBB.');
    }

    if (formData.cbu.length !== 22) {
        newErrors.cbu = 'El CBU debe tener exactamente 22 números';
        errorMessages.push('El CBU debe tener exactamente 22 números.');
    } else if (!/^\d{22}$/.test(formData.cbu)) {
        newErrors.cbu = 'El CBU debe contener solo números';
        errorMessages.push('El CBU debe contener solo números.');
    }

    if (formData.cuit.length !== 11) {
        newErrors.cuit = 'El CUIT debe tener exactamente 11 números';
        errorMessages.push('El CUIT debe tener exactamente 11 números.');
    } else if (!/^\d{11}$/.test(formData.cuit)) {
        newErrors.cuit = 'El CUIT debe contener solo números';
        errorMessages.push('El CUIT debe contener solo números.');
    }

    // Validar archivos obligatorios
    if (showFileUpload) {
        if (!files.afip?.length && (formData.situacionIVA === 'Responsable Inscripto' || formData.situacionIVA === 'Monotributista' || formData.situacionIVA === 'Exento')) {
            newErrors.afip = 'Debe cargar el archivo de AFIP';
            errorMessages.push('Debe cargar el archivo de AFIP.');
        }
        if (!files.iibb?.length && (formData.condicionIngresosBrutos === 'Contribuyente Local' || formData.condicionIngresosBrutos === 'Convenio Multilateral' || formData.condicionIngresosBrutos === 'Regimen Simplificado')) {
            newErrors.iibb = 'Debe cargar el archivo de IIBB o CM 05';
            errorMessages.push('Debe cargar el archivo de IIBB o CM 05.');
        }
        if (!files.formulario1276?.length && (formData.condicionIngresosBrutos === 'Contribuyente Local' || formData.condicionIngresosBrutos === 'Convenio Multilateral' || formData.condicionIngresosBrutos === 'Regimen Simplificado')) {
            newErrors.formulario1276 = 'Debe cargar el Formulario 1276 API';
            errorMessages.push('Debe cargar el Formulario 1276 API.');
        }
    }

    // Mostrar alertas si hay errores
    if (errorMessages.length > 0) {
        alert(errorMessages.join('\n'));
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
};
    
    
    
    

    const handleNext = () => {
        if (validateForm()) {
            setShowFileUpload(true);
        } else {
            alert('Por favor, complete todos los campos requeridos.');
        }
    };

    const handleBack = () => {
        setShowFileUpload(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!validateForm()) {
            alert('Por favor, corrija los errores antes de enviar el formulario.');
            return;
        }
        
        setIsSubmitting(true);
        
        const data = new FormData();
        Object.keys(formData).forEach(key => {
            data.append(key, formData[key]);
        });
        
        Object.keys(files).forEach(fileCategory => {
            files[fileCategory].forEach((file, index) => {
                const fileName = `${formData.razonSocial}_${formData.cuit}_${fileCategory}_${index + 1}.pdf`;
                data.append('archivos', file, fileName);
            });
        });
        
        try {
            const response = await fetch(`${config.apiBaseUrl}/api/solicitudes1`, {
                method: 'POST',
                body: data
            });
            if (response.ok) {
                setSubmitSuccess(true);
                setFiles({
                    exclusionCertificados: [],
                    afip: [],
                    contribuyenteLocal: [],
                    formulario1276: []
                });
                console.log('Archivos reseteados a su estado inicial.');  // Mensaje de consola
                setTimeout(() => {
                    window.location.href = '/';
                }, 4500);
            } else {
                alert('Error al enviar el formulario');
            }
        } catch (error) {
            alert('Error al enviar el formulario');
        } finally {
            setIsSubmitting(false);
        }
    };
    
    
    

    return (
        //HOLA AMIGO PODEMOS VER TU IP
        <div className='App'>
            <div className="overlay" style={overlayStyles}></div>
            <div className="container mt-5" style={{ maxWidth: '2000px', marginBottom: '0px' }}> 
                <div className="row justify-content-center">
                    <div className="col-md-12" style={formContainerStyles} data-aos="flip-left">
                        <div className="text-center">
                            <img src={`${process.env.PUBLIC_URL}/Marcologo1.png`} alt="Logo" style={{ height: "120px" }} />
                            <br></br>
                            <br></br>
                            <br></br>


                        </div>
                        {isSubmitting ? (
                            <div className="text-center">
                                <img style={{ width: "1000px", height: "1000px" }} src={`${process.env.PUBLIC_URL}/log.gif`} alt="Enviando..." />
                            </div>
                        ) : submitSuccess ? (
                            <div className="text-center">
                                <img src={`${process.env.PUBLIC_URL}/log1.png`} style={{ width: "650px", height: "650px" }} alt="Enviado con éxito" />
                                <h2 style={{ fontSize: "45px", color: "grey", textShadow: "0px 0px 6px", fontFamily: '"Josefin Sans", sans-serif' }}>SU SOLICITUD FUE ENVIADA CORRECTAMENTE</h2>
                                <p style={{ fontSize: "45px", color: "#ff6600", textShadow: "0px 0px 6px", fontFamily: '"Josefin Sans", sans-serif' }}>BIENVENIDO A MARCOPOLO ARGENTINA</p>
                            </div>
                        ) : (
                            <form onSubmit={handleSubmit} className="text-center">
                                {!showFileUpload && (
                                    <>
                                        <h2 className="text-center mb-4" style={titulo}>Datos del proveedor</h2>
                                        <p style={{ fontSize: "45px", color: "#ff6600", textShadow: "0px 0px 6px", fontFamily: '"Josefin Sans", sans-serif' }}>Todos los campos son requeridos</p>
                                        <div className="row justify-content-center">
                                            <div className="col-sm-10 col-md-8 col-lg-6 mb-3">
                                                <label htmlFor="razonSocial" className="form-label label2">Razón Social</label>
                                                <input type="text" className="form-control input-lg" id="razonSocial" value={formData.razonSocial} onChange={handleChange} placeholder="Ingresa la razón social" required />
                                                {errors.razonSocial && <small className="text-danger" style={{fontSize:"30px"}}>{errors.razonSocial}</small>}
                                            </div>
                                            <div className="col-sm-10 col-md-8 col-lg-6 mb-3">
                                                <label htmlFor="direccion" className="form-label label2">Dirección</label>
                                                <input type="text" className="form-control input-lg" id="direccion" value={formData.direccion} onChange={handleChange} placeholder="Ingresa la dirección" required />
                                                {errors.direccion && <small className="text-danger" style={{fontSize:"30px"}}>{errors.direccion}</small>}
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-sm-10 col-md-8 col-lg-6 mb-3">
                                                <label htmlFor="localidad" className="form-label label2">Localidad</label>
                                                <input type="text" className="form-control input-lg" id="localidad" value={formData.localidad} onChange={handleChange} placeholder="Ingresa la localidad" required />
                                                {errors.localidad && <small className="text-danger" style={{fontSize:"30px"}}>{errors.localidad}</small>}
                                            </div>
                                            <div className="col-sm-10 col-md-8 col-lg-6 mb-3">
                                                <label htmlFor="provincia" className="form-label label2">Provincia</label>
                                                <select
                                                    className="form-control select-lg"
                                                    id="provincia"
                                                    value={formData.provincia}
                                                    onChange={(e) => setFormData({ ...formData, provincia: e.target.value })}
                                                    required >
                                                    <option value="" disabled>Selecciona tu provincia</option>
                                                    {provincias.map(provincia => (
                                                        <option key={provincia} value={provincia}>{provincia}</option>
                                                    ))}
                                                </select>
                                                {errors.provincia && <small className="text-danger" style={{fontSize:"30px"}}>{errors.provincia}</small>}
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-sm-10 col-md-8 col-lg-6 mb-3">
                                                <label htmlFor="codigoPostal" className="form-label label2">Código Postal</label>
                                                <input type="text" className="form-control input-lg" id="codigoPostal" value={formData.codigoPostal} onChange={handleChange} placeholder="Ingresa el código postal" required />
                                                {errors.codigoPostal && <small className="text-danger" style={{fontSize:"30px"}}>{errors.codigoPostal}</small>}
                                            </div>
                                            <div className="col-sm-10 col-md-8 col-lg-6 mb-3">
                                                <label htmlFor="telefono" className="form-label label2">Teléfono</label>
                                                <input type="text" className="form-control input-lg" id="telefono" value={formData.telefono} onChange={handleChange} placeholder="Ingresa el teléfono" required />
                                                {errors.telefono && <small className="text-danger" style={{fontSize:"30px"}}>{errors.telefono}</small>}
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-sm-10 col-md-8 col-lg-6 mb-3">
                                                <label htmlFor="cuit" className="form-label label2">CUIT</label>
                                                <input type="text" className="form-control input-lg" id="cuit" value={formData.cuit} onChange={handleChange} placeholder="Ingresa el CUIT" required />
                                                {errors.cuit && <small className="text-danger"style={{fontSize:"30px"}}>{errors.cuit}</small>}
                                            </div>
                                            <div className="col-sm-10 col-md-8 col-lg-6 mb-3">
                                                <label htmlFor="iibb" className="form-label label2">Nº de Inscripción IIBB</label>
                                                <input type="text" className="form-control input-lg" id="iibb" value={formData.iibb} onChange={handleChange} placeholder="Ingresa el Nº de Inscripción IIBB" required />
                                                {errors.iibb && <small className="text-danger" style={{fontSize:"30px"}}>{errors.iibb}</small>}
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-sm-10 col-md-8 col-lg-6 mb-3">
                                                <label htmlFor="cbu" className="form-label label2">CBU</label>
                                                <input type="text" className="form-control input-lg" id="cbu" value={formData.cbu} onChange={handleChange} placeholder="Ingresa el CBU" required />
                                                {errors.cbu && <small className="text-danger"style={{fontSize:"30px"}}>{errors.cbu}</small>}
                                            </div>
                                            <div className="col-sm-10 col-md-8 col-lg-6 mb-3">
                                                <label htmlFor="email" className="form-label label2">Email</label>
                                                <input type="email" className="form-control input-lg" id="email" value={formData.email} onChange={handleChange} placeholder="Ingresa el email" required />
                                                {errors.email && <small className="text-danger" style={{fontSize:"30px"}}>{errors.email}</small>}
                                            </div>
                                        </div>
                                        <div className="row justify-content-center mb-3">
                                            <div className="col-sm-10 col-md-8 col-lg-6">
                                                <label htmlFor="situacionIVA" className="form-label label2">Situación IVA</label>
                                                <select
                                                    className="form-control select-lg"
                                                    id="situacionIVA"
                                                    value={formData.situacionIVA}
                                                    onChange={(e) => setFormData({ ...formData, situacionIVA: e.target.value })}
                                                    required
                                                >
                                                    <option value="" disabled>Selecciona tu situación IVA</option>
                                                    <option value="Responsable Inscripto">Responsable Inscripto</option>
                                                    <option value="Monotributista">Monotributista</option>
                                                    <option value="Exento">Exento</option>
                                                </select>
                                                {errors.situacionIVA && <small className="text-danger" style={{fontSize:"30px"}}>{errors.situacionIVA}</small>}
                                            </div>
                                        </div>
                                        <div className="row justify-content-center mb-3">
                                            <div className="col-sm-10 col-md-8 col-lg-6">
                                                <label htmlFor="condicionIngresosBrutos" className="form-label label2">Condición ante Ingresos Brutos</label>
                                                <select
                                                    className="form-control select-lg"
                                                    id="condicionIngresosBrutos"
                                                    value={formData.condicionIngresosBrutos}
                                                    onChange={(e) => setFormData({ ...formData, condicionIngresosBrutos: e.target.value })}
                                                    required>
                                                    <option value="" disabled>Selecciona tu condición</option>
                                                    <option value="Contribuyente Local">Contribuyente Local</option>
                                                    <option value="Convenio Multilateral">Convenio Multilateral</option>
                                                    <option value="Regimen Simplificado">Regimen Simplificado</option>
                                                    <option value="Exento">Exento</option>
                                                </select>
                                                {errors.condicionIngresosBrutos && <small className="text-danger" style={{fontSize:"30px"}}>{errors.condicionIngresosBrutos}</small>}
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-md-4">
                                            <br></br>
                                            <br></br>


                                                <button type="button" className="btn btn-primary1" onClick={handleNext}>Siguiente</button>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {showFileUpload && (
                                    <>
                                        <h3 className="mt-5" style={titulo}>Documentos a Cargar</h3>
                                        <p style={{ color: 'black' }}>
      LOS DOCUMENTOS DEBEN SER EN FORMATO PDF
    </p>                                        <br />
                                        {(formData.situacionIVA === 'Responsable Inscripto' || formData.situacionIVA === 'Monotributista' || formData.situacionIVA === 'Exento') && (
                                            <div className="row justify-content-center mb-3">
                                                <div className="col-sm-10 col-md-8 col-lg-6">
                                                    <label htmlFor="afip" className="form-label label3">Constancia de inscripción AFIP (OBLIGATORIO)</label>
                                                    <input type="file" id="afip" name="afip" className="form-control input-lg1" accept="application/pdf" onChange={(e) => handleFileChange(e, "afip")} />
                                                    {errors.afip && <small className="text-danger" style={{fontSize:"30px"}}>{errors.afip}</small>}
                                                </div>
                                            </div>
                                        )}
                                        {(formData.condicionIngresosBrutos === 'Contribuyente Local' || formData.condicionIngresosBrutos === 'Convenio Multilateral' || formData.condicionIngresosBrutos === 'Regimen Simplificado') && (
                                            <div className="row justify-content-center mb-3">
                                                <div className="col-sm-10 col-md-8 col-lg-6">
                                                    <label htmlFor="iibb" className="form-label label3">Constancia de Inscripción IIBB o Formulario CM 05 (OBLIGATORIO)</label>
                                                    <input type="file" id="iibb" name="iibb" className="form-control input-lg1" accept="application/pdf" onChange={(e) => handleFileChange(e, "iibb")} />
                                                    {errors.iibb && <small className="text-danger" style={{fontSize:"30px"}}>{errors.iibb}</small>}
                                                </div>
                                            </div>
                                        )}
                                        {formData.condicionIngresosBrutos && (
                                            <div className="row justify-content-center mb-3">
                                                <div className="col-sm-10 col-md-8 col-lg-6">
                                                    <label htmlFor="formulario1276" className="form-label label3">Formulario 1276 API (OBLIGATORIO)</label>
                                                    <input type="file" id="formulario1276" name="formulario1276" className="form-control input-lg1" accept="application/pdf" onChange={(e) => handleFileChange(e, "formulario1276")} />
                                                    {errors.formulario1276 && <small className="text-danger" style={{fontSize:"30px"}}>{errors.formulario1276}</small>}
                                                </div>
                                            </div>
                                        )}
                                        <div className="row justify-content-center mb-3">
                                            <div className="col-sm-10 col-md-8 col-lg-6">
                                                <label htmlFor="exclusionCertificados" className="form-label label3">Certificados de exclusión o atenuación de alícuotas que posean de impuestos nacionales</label>
                                                <input
                                                    type="file"
                                                    id="exclusionCertificados"
                                                    name="exclusionCertificados"
                                                    className="form-control input-lg1"
                                                    accept="application/pdf"
                                                    multiple
                                                    onChange={(e) => handleFileChange(e, "exclusionCertificados")}
                                                />
                                                {errors.files && <small className="text-danger" style={{fontSize:"30px"}}>{errors.files}</small>}
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-md-4">
                                                <br></br>
                                                <br></br>

                                                <button type="button" className="btn btn-secondary1 btn-lg" onClick={handleBack}>Atrás</button>
                                                <br></br>
                                                <br></br>

                                                <button type="submit" className="btn btn-primary1 btn-lg" disabled={Object.keys(errors).length > 0}>Enviar</button>
                                                </div>
                                        </div>
                                    </>
                                )}
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Clientes;
